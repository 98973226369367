<template>
  <div class="projects-info-list item-list item-report-summary">
    <div
      class="summary-toolbar"
    >
      <h2>Project Sales Process Report ({{total}})</h2>
    </div>

    <table class="no-row-border">
      <thead>
        <tr>
          <th>Key</th>
          <th>Name</th>
          <th>Company</th>
          <th>Status</th>
          <th>Sales Process Notes</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="project in projects"
          :key="project.key"
        >
          <td>
            <a target="blank" :href="consoleLink+ 'admin/projects/'+project.key
              +'/preview?search='+project.key">{{project.key}}</a>
          </td>
          <td>
            {{project.name}}
          </td>
          <td>
            {{project.company_name ? project.company_name : '-'}}
          </td>
          <td>
            {{project.status}}
          </td>
          <td>
            {{project.sales_process_notes ? project.sales_process_notes : '-'}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import Config from '@/config';

export default {
  props: {
    projects: Object,
    total: Number,
  },
  data() {
    return {
      consoleLink: Config.consoleHost,
    };
  },
};
</script>
