<template>
  <div class="projects-info-list item-list item-report-summary">
    <div
      class="summary-toolbar"
    >
      <h2>Customer Sales Process Report ({{total}})</h2>
    </div>

    <table class="no-row-border">
      <thead>
        <tr>
          <th>User</th>
          <th>Name</th>
          <th>Email</th>
          <th>E.V</th>
          <th>P</th>
          <th>Notes</th>
          <th>Legacy</th>
          <th>Last Logged in</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="customer in customers"
          :key="customer.key"
        >
          <td>
            <a target="blank" :href="consoleLink+ 'admin/users-info/'+customer.user
              +'/preview?search='+customer.user">{{customer.user}}</a>
          </td>
          <td>
            {{customer.name}}
          </td>
          <td>
            <a :href="'mailto:'+customer.email">{{customer.email}}</a>
          </td>
          <td>
            {{customer.email_verified ? '✅': 'No'}}
          </td>
          <td>
            {{customer.no_of_projects}}
          </td>
          <td>
            {{customer.sales_process_notes ? customer.sales_process_notes : '-'}}
          </td>
          <td>
            {{customer.legacy_access ? '✅': 'No'}}
          </td>
          <td>
            {{ $filters.timeStamp(customer.last_login) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import Config from '@/config';

export default {
  props: {
    customers: Object,
    total: Number,
  },
  data() {
    return {
      consoleLink: Config.consoleHost,
    };
  },
};
</script>
