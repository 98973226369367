<template>
  <div class="screen-admin-page screen-admin-usaga-report sales-report">
    <div>
      <div class="toolbar">
        <div class="title">
          <h1>Sales Process Report</h1>
        </div>
        <div class="tools">
          <div class="filter-items dropdown">
            <select v-model="label" @change="onSelectedStep($event)">
              <option
                v-for="group in SalesProcessStep.allItems()"
                :key="group.value"
                :value="group.value"
                >
                {{group.label}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="filterables">
        <div class="filter-container">
          <ul class="filter-items">
            <li
              v-for="item in filterables"
              :key="item.key"
              :class="{
                active: values.title === item,
                selectable: !item.isCheckbox,
                selected: item.selected,
                hr: item.key === '-',
              }"
              @click="onSelectedFilteritem(item)"
            >
              {{item}}
            </li>
          </ul>
        </div>
        <div>
          <div v-if="values.projects">
            <rz-request-state :state="state" />
            <div
              class="usage-report admin-screen-content"
              v-if="state.status.isLoaded"
            >
              <div class="item-list-row item-list">
                <ProjectSalesReport :projects="projectSalesProcessReport.projects"
                  :total="projectSalesProcessReport.total_projects" />
              </div>
            </div>
          </div>
          <div v-else>
            <rz-request-state :state="state" />
            <div
              class="usage-report admin-screen-content"
            >
              <div class="item-list-row item-list">
                <CustomerSalesReport :customers="customerSalesProcessReport.customers"
                  :total="customerSalesProcessReport.total_customers"/>
              </div>
            </div>
          </div>
          <div class="filter-pagination" v-if="pageNumbers">
            <ul>
              <li
                v-for="page in pageNumbers"
                :key="page"
                @click="fetchPage(page)"
              >
                {{page}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ref,
  reactive,
  onMounted,
  computed,
} from 'vue';
import { RequestState, RequestStateStatus } from '@/api/core';
import {
  ProjectList,
  SalesProcessStep,
} from '@/store/modules/admin';
import ProjectSalesReport from '@/components/admin/ProjectSalesReport.vue';
import CustomerSalesReport from '@/components/admin/CustomerSalesReport.vue';

export default {
  components: {
    ProjectSalesReport,
    CustomerSalesReport,
  },
  setup() {
    const state = ref(new RequestState());
    const projectSalesProcessReport = ref([]);
    const customerSalesProcessReport = ref([]);
    let filterables = ref([]);
    const pageNumbers = ref([]);
    let step = 2001;
    const label = 2001;
    const page = 1;
    const values = reactive({
      customers: false,
      projects: true,
      title: 'Projects',
    });
    filterables = [
      'Projects',
      'Customers',
    ];

    const ProjectSalesProcessReport = (val) => {
      const params = {};
      params.step = step;
      params.page = page || val;
      params.step = step;
      if (val) {
        params.page = val;
      } else {
        params.page = page;
      }
      if (projectSalesProcessReport.value && projectSalesProcessReport.value.total_projects && val) {
        params.total_projects = projectSalesProcessReport.value.total_projects;
      }
      state.value.status = RequestStateStatus.Loading;
      return ProjectList.projectSalesProcessReport(params).then((resp) => {
        projectSalesProcessReport.value = resp.data.data;
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };
    const CustomerSalesProcessReport = (val) => {
      const params = {};
      params.step = step;
      if (val) {
        params.page = val;
      } else {
        params.page = page;
      }
      if (customerSalesProcessReport.value && customerSalesProcessReport.value.total_customers && val) {
        params.total_customers = customerSalesProcessReport.value.total_customers;
      }
      state.value.status = RequestStateStatus.Loading;
      return ProjectList.customerSalesProcessReport(params).then((resp) => {
        customerSalesProcessReport.value = resp.data.data;
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };
    const fetchPage = (pageNum) => {
      if (values.customers) {
        CustomerSalesProcessReport(pageNum);
      } else {
        ProjectSalesProcessReport(pageNum);
      }
    };
    const pages = () => {
      let currentPage;
      let total;
      const maxPageItems = 10;
      const items = [];
      if (projectSalesProcessReport.value.pagination_params
        && projectSalesProcessReport.value.pagination_params.total_pages > 1 && values.projects) {
        currentPage = parseInt(page, 10);
        total = projectSalesProcessReport.value.pagination_params.total_pages;
      } else if (customerSalesProcessReport.value.pagination_params
        && customerSalesProcessReport.value.pagination_params.total_pages > 1 && values.customers) {
        currentPage = parseInt(page, 10);
        total = customerSalesProcessReport.value.pagination_params.total_pages;
      } else {
        pageNumbers.value = null;
        return pageNumbers;
      }
      let left = 0;
      let right = Math.min(total, maxPageItems);
      if (total > maxPageItems) {
        left = Math.max(0, currentPage - (maxPageItems / 2));
        right = left + maxPageItems - 1;
      }
      for (let i = left; i < right; i += 1) {
        items.push(i + 1);
      }

      if (items[0] !== 1) {
        items.splice(0, 0, 1);
      }

      if (items[items.length - 1] !== total) {
        items.push(total);
      }
      pageNumbers.value = items;
      return pageNumbers;
    };

    const pagination = computed(() => {
      pages();
    });

    const onSelectedFilteritem = (item) => {
      if (item === 'Projects') {
        values.projects = true;
        values.customers = false;
        values.title = 'Projects';
      } else {
        values.projects = false;
        values.customers = true;
        values.title = 'Customers';
      }
      pages();
    };

    const onSelectedStep = (event) => {
      step = parseInt(event.target.value, 10);
      if (values.projects) {
        ProjectSalesProcessReport();
      } else {
        CustomerSalesProcessReport();
      }
    };

    onMounted(() => {
      ProjectSalesProcessReport();
      CustomerSalesProcessReport();
    });

    return {
      state,
      projectSalesProcessReport,
      customerSalesProcessReport,
      filterables,
      onSelectedFilteritem,
      values,
      step,
      label,
      pagination,
      SalesProcessStep,
      onSelectedStep,
      pageNumbers,
      pages,
      fetchPage,
    };
  },
};
</script>
<style lang="scss" scoped>
.sales-report {
  .filter-container {
    width: size(230);
    .filter-items {
      &.dropdown {
        margin: 0;
        select {
          width: size(180);
          height: size(30);
        }
      }
    }
  }
  .filter-pagination {
    padding: size(20) size(50);
    ul {
      li {
        margin-right: size(5);
        background-color: var(--admin--color-level1);
        padding: size(6) size(16);
        font-size: size(12);
        border-radius: size(3);
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .filterables {
    margin-left: size(30);
    h2 {
      font-size: size(16);
      line-height: size(20);
      font-weight: 600;
      margin: size(35) 0 size(15);
    }
    .admin-screen-content {
      .item-list {
        padding-top: 0;
      }
    }
  }
}
</style>
